// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { createUploadTask } from '../../utils';
import { assertValidationError } from '../../../../errors/utils/assertValidationError';
import { StorageValidationErrorCode } from '../../../../errors/types/validation';
import { DEFAULT_PART_SIZE, MAX_OBJECT_SIZE } from '../../utils/constants';
import { byteLength } from './byteLength';
import { putObjectJob } from './putObjectJob';
import { getMultipartUploadHandlers } from './multipart';
export function uploadData(input) {
    const { data } = input;
    const dataByteLength = byteLength(data);
    assertValidationError(dataByteLength === undefined || dataByteLength <= MAX_OBJECT_SIZE, StorageValidationErrorCode.ObjectIsTooLarge);
    if (dataByteLength !== undefined && dataByteLength <= DEFAULT_PART_SIZE) {
        // Single part upload
        const abortController = new AbortController();
        return createUploadTask({
            isMultipartUpload: false,
            job: putObjectJob(input, abortController.signal, dataByteLength),
            onCancel: (message) => {
                abortController.abort(message);
            },
        });
    }
    else {
        // Multipart upload
        const { multipartUploadJob, onPause, onResume, onCancel } = getMultipartUploadHandlers(input, dataByteLength);
        return createUploadTask({
            isMultipartUpload: true,
            job: multipartUploadJob,
            onCancel: (message) => {
                onCancel(message);
            },
            onPause,
            onResume,
        });
    }
}
